<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
  <div v-if="orders">
    <vs-table
      ref="table"
      search
      :data="orders"
    >
      <template slot="thead">
        <vs-th sort-key="id">رقم الطلب</vs-th>
        <vs-th sort-key="total">المبلغ الإجمالي</vs-th>
        <vs-th sort-key="created_at">تاريخ التوصيل</vs-th>
        <vs-th>للزبون</vs-th>
        <vs-th sort-key="order_status_id">حالة الطلب</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.id }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.total_amount }}LYD</p>
            </vs-td>

            <vs-td>
              <vs-chip class="product-order-status">
                {{ tr.delivery_date }}
              </vs-chip>
            </vs-td>

            <vs-td>
              {{ tr.user.name }}
            </vs-td>

            <vs-td>
              <vs-chip
                transparent :color="getOrderStatusColor(tr.statu.id)"
                class="product-order-status"
              >
                {{ tr.statu.status }}</vs-chip
              >

            </vs-td>

            <vs-td class="whitespace-no-wrap">
              
              <feather-icon
                icon="RepeatIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                class="ml-2"
                @click.stop="confirm(tr.id)"
              />
              
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      @change="fetchArchivedOrders(current_Page)"
    ></vs-pagination>
    </div>
    <EmptyState page="طلبات مؤرشفة" display="false" v-else></EmptyState>
  </div>
</template>

<script>
import EmptyState from "@/views/emptyState.vue";
  import {
    mapActions
  } from 'vuex'
export default {
  components: {
    EmptyState,
  },
  data() {
    return {
      current_Page: 1,
      order_id:'',
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    lastPage(){
      return this.$store.state.orders.pagination
    },
    orders(){
      return this.$store.state.orders.archived
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.orders.length;
    },
  },
  methods: {
    ...mapActions('orders', [
        'fetchArchivedOrders',
        'retrieveOrder'
      ]),
    confirm(id) {
      this.order_id = id
     this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: 'تأكيد',
        text: 'هل انت متأكد من إستعادة هذا الطلب؟',
        acceptText: 'تأكيد',
        cancelText:'الغاء',
        accept: this.retrieve,
      })
    },
    retrieve(){
        this.retrieveOrder(this.order_id)
        .then((response)=>{
          this.successDialog(response.data.message)
        }).catch((error)=>{
           this.errorDialog(error)
        })
    },
    getOrderStatusColor(status) {
      if (status == 1) return "warning";
      if (status == 2) return "#f7cb73";
      if (status == 3) return "#af78ff";
      if (status == 4) return "#50d2a0";
      if (status == 5) return "success";
      if (status == 6) return "dark";
      if (status == 7) return "danger";
      return "primary";
    },
  },
  mounted() {
    this.isMounted = true;
  },
  created(){
    this.fetchArchivedOrders(this.current_Page)
  }
};
</script>
